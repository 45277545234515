var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "aspect-video"
  }, [_c('video', {
    ref: "video",
    staticClass: "h-auto object-contain absolute inset-0 z-10 pointer-events-all",
    staticStyle: {
      "max-width": "100%"
    },
    attrs: {
      "controls": ""
    }
  }), _c('div', {
    staticClass: "absolute inset-0 text-center -z-1 pointer-events-none flex items-center justify-center text-white"
  }, [_vm.message ? _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.message)
    }
  }) : _vm.loading ? _c('div', {
    staticClass: "absolute inset-0 h-full flex items-center justify-center"
  }, [_c('div', {
    staticClass: "mt-2 text-sm absolute m-auto"
  }, [_c('Icon', {
    staticClass: "mb-2",
    attrs: {
      "name": "svg-spinners:90-ring-with-bg",
      "size": "40"
    }
  }), _c('br'), _vm._v(" Betöltés... ")], 1)]) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }